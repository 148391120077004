import { useEffect } from "react";
import { activeButton } from "../../../utils/keys/KeyHandlers";
import { useGame } from "../../game/GameContext";
import "./index.css";

export default function Promotion() {
    const { promotion, setPromotion } = useGame();

    if (!promotion.open) {
        return null;
    }

    /* If the user clicks outside the promotion dialog, close it. */
    window.addEventListener("click", (e) => {
        if (e.target.className === "overlay") {
            setPromotion({ open: false, type: null });
        }
        return () => {
            window.removeEventListener("click", () => {
                setPromotion({ open: false, type: null });
            });
        };
    });

    useEffect(() => {
        const startButton = document.querySelectorAll("button")[activeButton];
        startButton?.focus();
    });

    return (
        <div className="overlay" data-testid="promotion-dialog">
            <div className="promotion-modal">
                <h1>Promote Pawn</h1>
                <div className="promotion-options">
                    <button
                        className="chess-button"
                        onClick={() => {
                            setPromotion({ open: false, type: "q" });
                        }}
                    >
                        Queen
                    </button>
                    <button
                        className="chess-button"
                        onClick={() => {
                            setPromotion({ open: false, type: "r" });
                        }}
                    >
                        Rook
                    </button>
                    <button
                        className="chess-button"
                        onClick={() => {
                            setPromotion({ open: false, type: "b" });
                        }}
                    >
                        Bishop
                    </button>
                    <button
                        className="chess-button"
                        onClick={() => {
                            setPromotion({ open: false, type: "n" });
                        }}
                    >
                        Knight
                    </button>
                    <button
                        className="chess-button"
                        onClick={() => {
                            setPromotion({ open: false, type: null });
                        }}
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    );
}
