/**
 * App.js
 * Main component for the chess app.
 *
 * @author Braden Zingler
 * Last modified 10/09/2024
 */
import "./App.css";
import React, { useEffect } from "react";
import { useGame } from "./components/game/GameContext";
import GameOver from "./components/interfaces/GameOver/GameOver";
import Menu from "./components/interfaces/Menu/Menu";
import Candle from "./components/visual/Candle";
import Cards from "./components/visual/Cards";
import CoffeeMug from "./components/visual/CoffeeMug";
import CustomChessboard from "./components/game/CustomChessboard/CustomChessboard";
import Promotion from "./components/interfaces/Promotion";
import { initGameCenter } from "./utils/GameCenter";
import StartMenu from "./components/interfaces/StartMenu";
import MultiplayerMenu from "./components/multiplayer/MultiplayerMenu";
import { addEventListeners } from "./utils/utils";
import LoadingScreen from "./components/interfaces/LoadingScreen";
import { useWebsocket } from "./utils/multiplayer/useWebsocket";
import JoinGame from "./components/multiplayer/JoinGame";
import TurnIndicator from "./components/visual/TurnIndicator";

/**
 * Handles the main logic for the chess app.
 * Sets up the game state, restarts the game, and renders the game board.
 *
 * @component App
 * @returns {JSX.Element} The main component for the chess app.
 */
export default function App() {
    const {
        gameState,
        setGameState,
        multiplayerState,
        setMultiplayerState,
        loading,
    } = useGame();
    useWebsocket(
        multiplayerState,
        gameState,
        setGameState,
        setMultiplayerState,
    );

    /**
     * Load the game state from localStorage when the window loads.
     */
    window.onload = () => {
        document.body.style.backgroundImage = "url('images/table.webp')"; // Use preloaded version
        addEventListeners(setGameState, setMultiplayerState, gameState);
        initGameCenter();
        const savedDifficulty = localStorage.getItem("aiDifficulty");
        if (savedDifficulty) {
            setGameState((prevState) => ({
                ...prevState,
                aiDifficulty: parseInt(savedDifficulty),
            }));
        }
    };

    /**
     * Update the game state to localStorage whenever the game position changes.
     * This saves the game state only for AI games.
     */
    useEffect(() => {
        if (gameState.game && gameState.isAIGame) {
            localStorage.setItem("ai-chessGameState", gameState.game.fen());
            localStorage.setItem("aiDifficulty", gameState.aiDifficulty);
        }
    }, [
        gameState.game,
        gameState.fen,
        gameState.aiDifficulty,
        gameState.isAIGame,
    ]);


    // Show the loading screen if the user is joining a multiplayer game.
    if ((multiplayerState.joinGame && !gameState.showGame) ||loading.isLoading) {
        return <LoadingScreen />;
    } else if (multiplayerState.showUserNamePage) {
        return <JoinGame />;
    }

    return gameState.showGame ? (
        <div className="chess-app" data-testid="chess-app">
            {multiplayerState.isHost && !gameState.isAIGame && 
                <div 
                    id="player-name1" 
                    className={`player-name ${gameState.game.turn() !== "w" && "flashing-turn"}`}>
                    {multiplayerState.playerTwo}
                </div>
            }
            {multiplayerState.isHost && !gameState.isAIGame &&
                <div id="player-name2" 
                    className={`player-name ${gameState.game.turn() === "w" && "flashing-turn"}`}>
                        {multiplayerState.playerOne}
                </div>
            }
            <TurnIndicator />
            <Candle />
            <Cards />
            <Menu />
            <CoffeeMug />
            <GameOver />
            <Promotion />
            <CustomChessboard />
        </div>
    ) : (
        <div className="chess-app" data-testid="chess-app">
            <StartMenu />
            <MultiplayerMenu />
        </div>
    );
}
