/**
 * utils.js
 * The utils file contains utility functions that are used throughout the application.
 *
 * @author Braden Zingler
 * Last modified 10/07/2024
 */
import { useState, useEffect } from "react";
import { handleKeyDown } from "./keys/KeyHandlers";

/**
 * Calculates the width of the chessboard.
 * This function makes the chessboard responsive.
 * @returns the width of the chessboard.
 */
export function calcBoardWidth() {
    const width = window.innerWidth;
    if (width > 1600) {
        return width * 0.37;
    } else if (width > 1300) {
        return width * 0.4;
    } else if (width > 1200) {
        return width * 0.45;
    } else if (width > 1100) {
        return width * 0.5;
    } else if (width > 900) {
        return width * 0.55;
    } else if (width > 800) {
        return width * 0.7;
    } else if (width > 700) {
        return width * 0.75;
    } else if (width > 600) {
        return width * 0.8;
    }
    return width * 0.95;
}

/**
 * Calculates the padding for the chessboard.
 * This provides a responsive padding for the chessboard border images.
 * These values were determined through calculations and trial & error. They should not be changed.
 * @returns the padding for the chessboard.
 */
export function calcBoardPadding() {
    const boardWidth = calcBoardWidth();
    return `${boardWidth * 0.0903}px ${boardWidth * 0.098}px ${boardWidth * 0.11534}px ${boardWidth * 0.09724}px`;
}

/**
 * Finds the king's position from a FEN string.
 * Example format: r3k2r/ppp2ppp/8/P2qpb2/1b1nn3/8/6PP/RNBQKBNR w KQkq - 0 11
 *
 * @param {String} fenString the FEN string to parse.
 * @returns the king's square position in algebraic notation.
 */
export function findKingFromFenString(fenString, color) {
    const boardPosition = fenString.split(" ")[0]; // Get the board position from the FEN string.
    const boardRows = boardPosition.split("/"); // Split the board position into rows.
    let kingPosition = null;

    // Search through the board rows to find the king's position.
    boardRows.forEach((row, rowIndex) => {
        row = row.replace(/\d/g, (digit) => ".".repeat(digit)); // Remove numbers from the row.

        // Find the king's position in the row. Upper case for white, lower case for black in FEN.
        const colIndex = color === "w" ? row.indexOf("K") : row.indexOf("k");

        if (colIndex !== -1) {
            // Convert the row and column index to algebraic notation.
            kingPosition = "abcdefgh"[colIndex] + (8 - rowIndex);
        }
    });
    return kingPosition;
}

/**
 * Checks if a move is a promotion.
 * This function is taken from the react-chessboard library.
 *
 * @param {String} sourceSquare the square the piece is moving from.
 * @param {String} targetSquare the square the piece is moving to.
 * @param {String} piece the piece being moved.
 */
export function calculateIsPromotion(sourceSquare, targetSquare, piece) {
    return (
        ((piece.color === "w" &&
            piece.type === "p" &&
            sourceSquare[1] === "7" &&
            targetSquare[1] === "8") || // White pawn promotion
            (piece.color === "b" &&
                piece.type === "P" &&
                sourceSquare[1] === "2" &&
                targetSquare[1] === "1")) && // Black pawn promotion
        Math.abs(sourceSquare.charCodeAt(0) - targetSquare.charCodeAt(0)) <= 1
    ); // Only allow promotion if moving one square horizontally
}

/**
 * Creates a random UUID for the user, and stores it in local storage.
 * @returns {String} a random UUID.
 */
export function getUserId() {
    const userID = localStorage.getItem("userID");
    if (!userID) {
        const newID = crypto.randomUUID();
        localStorage.setItem("userID", newID);
        return newID;
    }
    return userID;
}

/**
 * Removes the game ID from the URL after it has been used.
 * This allows for the player to host new games without the URL being messed up.
 */
export function removeGameIDFromURL() {
    window.history.pushState(
        "",
        document.title,
        window.location.pathname + window.location.search,
    );
}

/**
 * Initializes the game center.
 * @param {*} isMounted
 * @param {*} unmountDelay
 * @returns
 */
export const useMountTransition = (isMounted, unmountDelay) => {
    const [hasTransitionedIn, setHasTransitionedIn] = useState(false);

    useEffect(() => {
        let timeoutId;
        if (isMounted && !hasTransitionedIn) {
            setHasTransitionedIn(true);
        } else if (!isMounted && hasTransitionedIn) {
            timeoutId = setTimeout(
                () => setHasTransitionedIn(false),
                unmountDelay,
            );
        }

        return () => {
            clearTimeout(timeoutId);
        };
    }, [unmountDelay, isMounted, hasTransitionedIn]);

    return hasTransitionedIn;
};

/**
 * Adds event listeners to the window to handle game joining.
 * @param {React.useState} setGameState
 * @param {React.useState} setMultiplayerState
 * @param {React.useState} gameState the game state.
 */
export function addEventListeners(
    setGameState,
    setMultiplayerState,
    gameState
) {
    window.addEventListener("hashchange", () =>
        handleHashChange(setGameState, setMultiplayerState),
    );
    window.addEventListener("keydown", (e) =>
        handleKeyDown(e, gameState, setGameState),
    );
    // Override original console log so the parent iFrame can receive our logs to help debugging.
    const originalLog = console.log;
    console.log = function(...args) {
        // Send log message to parent
        window.parent?.postMessage({ action: 'log', message: args }, '*');
        originalLog.apply(console, args); // Preserve default behavior
    };
    handleHashChange(setGameState, setMultiplayerState);
    document.querySelectorAll("button")[0]?.focus();
}

/**
 * Updates the game state when the hash changes.
 * @param {*} setGameState the game state setter.
 * @param {*} setMultiplayerState the multiplayer state setter.
 */
function handleHashChange(setGameState, setMultiplayerState) {
    const gameID = window.location.hash.replace("#", "");
    if (gameID) {
        setGameState((prev) => ({
            ...prev,
            fen: null,
            id: gameID,
            game: null,
            isAIGame: false,
            showGame: false,
        }));
        setMultiplayerState((prev) => ({ ...prev, showUserNamePage: true }));
    }
}

export function getRightSquare(square) {
    if (!square) return;
    const col = square?.charCodeAt(0) - 97;
    const row = 8 - parseInt(square[1]);
    if (col === 7) return;
    return String.fromCharCode(col + 97 + 1) + (8 - row);
}

export function getLeftSquare(square) {
    if (!square) return;
    const col = square?.charCodeAt(0) - 97;
    const row = 8 - parseInt(square[1]);
    if (col === 0) return;
    return String.fromCharCode(col + 97 - 1) + (8 - row);
}

export function getUpSquare(square) {
    if (!square) return;
    const col = square?.charCodeAt(0) - 97;
    const row = 8 - parseInt(square[1]);
    if (row === 0) return;
    return String.fromCharCode(col + 97) + (8 - row + 1);
}

export function getDownSquare(square) {
    if (!square) return;
    const col = square?.charCodeAt(0) - 97;
    const row = 8 - parseInt(square[1]);
    if (row === 7) return;
    return String.fromCharCode(col + 97) + (8 - row - 1);
}