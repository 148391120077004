/**
 * CoffeeMug.js
 * The coffee mug component creates the coffee mug on the table next to the board.
 * It also has a steam span, which is animated using CSS.
 *
 * @author Braden Zingler
 * Last modified 09/17/24
 */
import "./index.css";

export default function CoffeeMug() {
    const showMug = window.innerWidth > 1200;

    if (showMug) {
        return (
            <div className="coffee-mug" data-testid="coffee-mug">
                <img
                    src="images/coffee.webp"
                    alt="Coffee Mug"
                    className={`mug-image`}
                />
                <span id="mug-steam" data-testid="mug-steam"></span>
            </div>
        );
    }
}
