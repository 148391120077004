import "./Menu.css";
import { useGame } from "../../game/GameContext";
import { FaHome } from "react-icons/fa";
import { IoMdRefresh } from "react-icons/io";
import { MdOutlineCancel } from "react-icons/md";
import { handleHome, restartGame } from "../../../utils/game/game";

/**
 * Creates the in-game menu on the top right of the screen.
 * @param {Object} props the properties for the Menu component.
 * @returns the Menu component
 */
export default function Menu() {
    const { multiplayerState, setMultiplayerState, gameState, setGameState } =
        useGame();

    /**
     * Forfeits the game by sending a message to the backend to end the game
     */
    function forfeitGame() {
        multiplayerState.ws.send(
            JSON.stringify({
                action: "forfeit",
                gameID: gameState.id,
                playerID: localStorage.getItem("userID"),
            }),
        );
    }

    return (
        <div className="menu">
            {/* AI difficulty selection */}
            {gameState.isAIGame && (
                <div id="difficulty">
                    <select
                        data-testid="difficulty"
                        name="select"
                        value={gameState.aiDifficulty}
                        onChange={(e) => {
                            setGameState((prevState) => ({
                                ...prevState,
                                aiDifficulty: parseInt(e.target.value),
                            }));
                        }}
                    >
                        <option value="0">Easy</option>
                        <option value="1">Medium</option>
                        <option value="2">Hard</option>
                        <option value="3">Very Hard</option>
                    </select>
                </div>
            )}

            {/* Restart, cancel, and home buttons */}
            {gameState.isAIGame && (
                <IoMdRefresh
                    data-testid="restart"
                    className="menu-icon restart"
                    onClick={() => restartGame(setGameState)}
                />
            )}
            {!gameState.isAIGame && !multiplayerState.isHost && !gameState.game.isGameOver() && !gameState.forfeit.forfeit && (
                <MdOutlineCancel
                    data-testid="cancel"
                    className="menu-icon"
                    onClick={forfeitGame}
                />
            )}
            {(gameState.isAIGame || multiplayerState.isHost) && <FaHome
                data-testid="home"
                className="menu-icon"
                onClick={() => handleHome(setGameState, setMultiplayerState)}
            />}
        </div>
    );
}
