/**
 * CustomChessboard.js
 * Handles the chessboard logic for the chess app.
 *
 * @author Braden Zingler
 * Last modified 11/13/2024
 */
import "./CustomChessboard.css";
import { CustomBoardConstants } from "../../../utils/constants";
import {
    calcBoardPadding,
    calcBoardWidth,
    findKingFromFenString,
    calculateIsPromotion,
    getRightSquare,
    getLeftSquare,
    getUpSquare,
    getDownSquare,
} from "../../../utils/utils";
import { useCustomPieces } from "../CustomPieces";
import usePlayAudio from "../../MoveAudio";
import { useGame } from "../GameContext";
import { sendMultiplayerGameUpdate } from "../../../utils/multiplayer/handlers";
import { aiMove } from "js-chess-engine";
import { useEffect, useState, useRef } from "react";
import { Chessboard, ChessboardDnDProvider } from "react-chessboard";
import { KeyCodes } from "../../../utils/keys/KeyCodes";
import { restartGame, handleHome } from "../../../utils/game/game";


/**
 * The CustomChessboard compo   nent is a wrapper around the react-chessboard component.
 * It provides additional functionality such as
 * highlighting available moves, drag-and-drop, and promotion.
 *
 * @param {obj} obj the props object
 * @returns the CustomChessboard component
 */
export default function CustomChessboard() {
    const [availableMoves, setAvailableMoves] = useState([]); // The available moves for the clicked piece.
    const [clickedPiece, setClickedPiece] = useState(null); // The piece that was clicked
    const [hoveredSquare, setHoveredSquare] = useState(null); // The square that is being hovered over
    const [pendingMove, setPendingMove] = useState(null); // Stores the pending move details
    const playerTurn = useRef(true); // Track if players turn
    const playAudio = usePlayAudio();

    const {
        gameState,
        setGameState,
        promotion,
        setPromotion,
        multiplayerState,
        setMultiplayerState,
        remoteNavigation,
        setRemoteNavigation,
    } = useGame();

    /**
     * Update playerTurn ref when gameState changes and make AI move appropriately.
     * This only runs when the AI is playing.
     */
    useEffect(() => {
        if (
            gameState.game.turn() === "b" &&
            playerTurn.current &&
            !gameState.game.isGameOver()
        ) {
            // Opponents turn
            playerTurn.current = gameState.game.turn() === "w"; // Mark it's opponents turn.
            if (gameState.isAIGame) {
                makeAImove();
            }
        } else if (gameState.game.turn() === "w") {
            // Players turn
            playerTurn.current = true;
        }
    }, [gameState.fen]);

    /**
     * Handle promotion and game movement when promotionType changes.
     * Pending moves are stored in state to complete the move after the promotion type is selected.
     * We need to wait for the promotion type to be selected before completing the move.
     */
    useEffect(() => {
        if (promotion.type && pendingMove) {
            handleMove(pendingMove.from, pendingMove.to, promotion.type);
            setPromotion({ open: false, type: null });
            setPendingMove(null);
        }
    }, [promotion.type, pendingMove, setPromotion, setPendingMove]);

    /**
     * Determines if the player is allowed to make a move.
     * @returns true if the player is allowed to make a move, false otherwise.
     */
    function allowMove() {
        return (
            !promotion.open 
            && gameState.game.turn() === multiplayerState.playerColor 
            && !multiplayerState.isHost
        );
    }

    /**
     * Handles the click event on a square.
     * Highlights available moves for the clicked piece.
     * @param {*} square the square that was clicked.
     */
    function onSquareClick(square) {
        setClickedPiece(null);
        setAvailableMoves([]);
        if (!allowMove()) return;

        // If clicking on an available move square, make the move
        if (clickedPiece && availableMoves.includes(square)) {
            const isPromotionMove = handlePromotion(clickedPiece, square);

            if (!isPromotionMove) {
                // If not a promotion move, it is just a normal move.
                handleMove(clickedPiece, square);
                setRemoteNavigation((prev) => ({
                    ...prev,
                    remoteClickSquare: null,
                }));
            }
        } else if (
            gameState.game.get(square) &&
            gameState.game.get(square).color === gameState.game.turn()
        ) {
            // If a new piece is clicked, highlight its available moves

            // Not the players piece.
            if (gameState.game.get(square).color !== multiplayerState.playerColor) return;

            const moves = gameState.game.moves({ square, verbose: true });
            const available = moves.map((move) => move.to);
            setClickedPiece(square);
            setAvailableMoves(available);
        }
    }

    /**
     * Handles the drop event (drag-and-drop).
     * Shows the available moves for the piece being dragged.
     * @param {String} sourceSquare the source square of the piece.
     * @param {String} targetSquare the target square of the piece.
     */
    function onDrop(sourceSquare, targetSquare) {
        if (
            sourceSquare === targetSquare ||
            promotion.open ||
            gameState.game.turn() !== multiplayerState.playerColor
        ) {
            // Did not move or promotion dialog is open.
            return false;
        }
        const isPromotionMove = handlePromotion(sourceSquare, targetSquare);
        if (!isPromotionMove) {
            handleMove(sourceSquare, targetSquare);
        }
        return true;
    }

    /**
     * Moves a piece on the board.
     * Handles the move and updates the game position.
     *
     * @param {String} sourceSquare the source square of the piece.
     * @param {String} targetSquare the target square of the piece.
     * @param {String} promotion the promotion type (defaulted to undefined).
     * @returns {boolean} true if the move was successful, false otherwise.
     */
    function handleMove(sourceSquare, targetSquare, promotion = undefined) {
        try {
            const move = gameState.game.move({
                from: sourceSquare,
                to: targetSquare,
                promotion: promotion,
            });
            if (move !== null) {
                playAudio();
                setGameState((prev) => ({
                    ...prev,
                    fen: gameState.game.fen(),
                    lastMove: { from: sourceSquare, to: targetSquare },
                }));
                // Send the game state to the opponent, to update both boards.
                if (!gameState.isAIGame) {
                    sendMultiplayerGameUpdate(
                        multiplayerState.ws,
                        gameState.id,
                        gameState.game.fen(),
                    );
                }
                setClickedPiece(null);
                setAvailableMoves([]);
                return true;
            }
        } catch (error) {
            // Invalid move
            console.log(error);
            return false;
        }
    }

    /**
     * Makes a move for the AI.
     * If the AI has a promotion, it defaults to a queen promotion since it can't make a choice.
     */
    async function makeAImove() {
        // Delay AI move for better UX
        await new Promise((r) =>
            setTimeout(r, CustomBoardConstants.AI_RESPONSE_DELAY),
        );
        const move = aiMove(gameState.game.fen(), gameState.aiDifficulty);
        const [from, to] = Object.entries(move)[0];
        handleMove(
            from.toLowerCase(),
            to.toLowerCase(),
            CustomBoardConstants.AI_PROMOTION_TYPE,
        );
    }

    /**
     * Handles the drag event.
     * Shows the available moves for the piece being dragged.
     * @param {String} sourceSquare the source square of the piece.
     */
    function onDragBegin(piece, sourceSquare) {
        // Hide the remote navigation square if a piece is moved with the mouse.
        setRemoteNavigation((prev) => ({
            ...prev,
            remoteOverSquare: null,
        }));
        const moves = gameState.game.moves({
            square: sourceSquare,
            verbose: true,
        });
        const available = moves.map((move) => move.to);
        setClickedPiece(sourceSquare);
        setAvailableMoves(available);
    }

    /**
     * Handles key events for keyboard navigation on board and menu.
     * TODO - this isn't clean, should eventually be in it's own file if possible.
     * @param {KeyboardEvent} event - The keyboard event object.
     */
    function handleKeyDown(event) {
        event.preventDefault();

        if (promotion.open) return;

        const menuButtons = document.querySelectorAll(".menu-icon");
        
        // Default the remoteOverSquare to a1 if it's null
        if (!remoteNavigation.remoteOverSquare && !remoteNavigation.isRemoteInMenuButtons) {
            setRemoteNavigation((prev) => ({
                ...prev,
                remoteOverSquare: clickedPiece || "a1"
            }));
        }

        let square; // The new chessboard square based on the input
        if (KeyCodes.Rights.includes(event.keyCode)) {
            
            if (remoteNavigation.isRemoteInMenuButtons) {
                if (remoteNavigation.activeMenuButton === 0) {
                    menuButtons[0]?.classList?.remove("focused-button");
                    menuButtons[1]?.classList?.add("focused-button");
                    setRemoteNavigation((prev) => ({ ...prev, activeMenuButton: 1 }));
                }
            } else {
                if (remoteNavigation.remoteOverSquare?.charAt(0) === "h") {
                    // Go up into the menu buttons
                    setHoveredSquare(null);
                    menuButtons[remoteNavigation.activeMenuButton].classList.add("focused-button");
                    setRemoteNavigation((prev) => ({
                        ...prev,
                        remoteOverSquare: null,
                        isRemoteInMenuButtons: true
                    }));
                    return;
                }
                square = getRightSquare(remoteNavigation.remoteOverSquare);
            }
        } else if (KeyCodes.Lefts.includes(event.keyCode)) {
            
            if (remoteNavigation.isRemoteInMenuButtons) {
                if (remoteNavigation.activeMenuButton === 1) {
                    menuButtons[1]?.classList?.remove("focused-button");
                    menuButtons[0]?.classList?.add("focused-button");
                    setRemoteNavigation((prev) => ({ ...prev, activeMenuButton: 0 }));
                } else {
                    // Go back to the board
                    menuButtons[0].classList.remove("focused-button");
                    setRemoteNavigation((prev) => ({
                        ...prev,
                        remoteOverSquare: "h8",
                        isRemoteInMenuButtons: false
                    }));
                }
            } else {
                square = getLeftSquare(remoteNavigation.remoteOverSquare);
            }
        
        } else if (KeyCodes.Ups.includes(event.keyCode)) {
            if (remoteNavigation.isRemoteInMenuButtons) return;

            if (remoteNavigation.remoteOverSquare?.charAt(1) === "8") {
                // Go up into the menu buttons
                setHoveredSquare(null);
                menuButtons[remoteNavigation.activeMenuButton].classList.add("focused-button");
                setRemoteNavigation((prev) => ({
                    ...prev,
                    remoteOverSquare: null,
                    isRemoteInMenuButtons: true
                }));
                return;
            }

            square = getUpSquare(remoteNavigation.remoteOverSquare);

        } else if (KeyCodes.Downs.includes(event.keyCode)) {
            
            // If currently in the menu buttons, go back to the board
            if (remoteNavigation.isRemoteInMenuButtons) {
                menuButtons[remoteNavigation.activeMenuButton]?.classList?.remove("focused-button");
                setRemoteNavigation((prev) => ({
                    ...prev,
                    isRemoteInMenuButtons: false,
                    activeMenuButton: 0,
                }));
                square = "h8"; // Go back to the board
            } else {
                square = getDownSquare(remoteNavigation.remoteOverSquare);
            }
        } else if (event.keyCode === KeyCodes.Enter) {

            // Select menu button if in menu buttons
            if (remoteNavigation.isRemoteInMenuButtons) {
                if (remoteNavigation.activeMenuButton === 0) {
                    restartGame(setGameState);
                } else {
                    handleHome(setGameState, setMultiplayerState);
                }
                return;
            }

            if (!allowMove()) return;

            // Prevent from selecting the piece if it's not the player's turn or the wrong color
            const piece = gameState.game.get(remoteNavigation.remoteOverSquare);
            if (availableMoves.length === 0 
                && piece.color 
                && piece.color !== multiplayerState.playerColor
            ) return;

            // Update the remote click square and make the move
            setRemoteNavigation((prev) => ({
                ...prev,
                remoteClickSquare: remoteNavigation.remoteOverSquare,
            }));
            onSquareClick(remoteNavigation.remoteOverSquare);
        } else if (event.keyCode === KeyCodes.Escape) {
            handleHome(setGameState, setMultiplayerState);
        }
        if (square) {
            setHoveredSquare(square);
            setRemoteNavigation((prev) => ({
                ...prev,
                remoteOverSquare: square,
            }));
        }
    }

    /**
     * Handles the promotion of a pawn.
     * Opens the promotion dialog if the move is a promotion.
     * Changes the pending move to the promotion type to complete the move.
     *
     * @param {String} sourceSquare the source square of the piece.
     * @param {String} targetSquare the target square of the piece.
     */
    function handlePromotion(sourceSquare, targetSquare) {
        if (!availableMoves.includes(targetSquare)) {
            return false; // Not a valid move
        }
        const piece = gameState.game.get(sourceSquare);

        if (calculateIsPromotion(sourceSquare, targetSquare, piece)) {
            /* Open promotion dialog and store the pending move */
            setPromotion({ open: true, type: null });
            setPendingMove({ from: sourceSquare, to: targetSquare, piece });
            return true;
        }
        return false;
    }

    /**
     * Highlights squares for available moves.
     * If the move is a capture, the square is highlighted in red.
     * If the game is in check, the king square is highlighted in red.
     * @returns the styles for the highlighted squares.
     */
    function highlightSquares() {
        const highlightStyles = {};

        
        // Highlight last move
        if (gameState.lastMove && gameState.lastMove.from && gameState.lastMove.to) {
            highlightStyles[gameState.lastMove.from] = {
                background: "rgba(255, 255, 0, 0.5)",
            };
            highlightStyles[gameState.lastMove.to] = {
                background: "rgba(255, 255, 0, 0.3)",
            };
        }
        
        // Highlight the king square with flashing red if in check
        if (gameState.game.inCheck()) {
            const kingSquare = findKingFromFenString(
                gameState.game.fen(),
                gameState.game.turn(),
            );
            highlightStyles[kingSquare] = CustomBoardConstants.KING_SQUARE_INCHECK_STYLES;
        }

        // Highlight clicked piece square
        if (clickedPiece) {
            highlightStyles[clickedPiece] = {
                background: `rgba(255, 255, 0, 0.5)`,
            };
        }

        // Highlight remote navigation square
        if (remoteNavigation.remoteOverSquare) {
            highlightStyles[remoteNavigation.remoteOverSquare] = {
                // add an inset border
                boxShadow: "inset 0 0 0 10px rgba(0,100,255,0.7)",
            };
        }

        // Highlight available moves when piece is selected/dragged.
        availableMoves.forEach((move) => {
            const rgba = gameState.game.get(move)
                ? CustomBoardConstants.CAPTURE_MOVE_SQUARE_STYLES // Red for capture moves.
                : CustomBoardConstants.AVAIL_MOVE_SQUARE_STYLES;

            highlightStyles[move] = remoteNavigation.remoteOverSquare === move
            ? {
                background: `radial-gradient(circle, rgba(0, 255, 0, 0.5) 30%, transparent 50%)`,
                borderRadius: "50%",
            } : {
                background: `radial-gradient(circle, ${rgba})`,
                borderRadius: "50%",
            };
            const div = document.querySelector(`[data-square="${move}"]`);
            div?.classList?.add("available-moves");
        });
        return highlightStyles;
    }

    /**
     * Checks if a piece can be dragged.
     * Color must be the same as the player's color and it must be the player's turn.
     * @param {*} piece the piece to check.
     * @returns true if the piece can be dragged, false otherwise.
     */
    function allowDrag({ piece }) {
        return (!gameState.game.isGameOver() 
            && piece.charAt(0) === multiplayerState.playerColor 
            && gameState.game.turn() === multiplayerState.playerColor
            && !multiplayerState.isHost
        );
    }

    useEffect(() => {
        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [clickedPiece, availableMoves, remoteNavigation, promotion.open]);

    return (
        <div
            className={`chessboard-wrapper`}
            data-testid="custom-chessboard"
        >
            <ChessboardDnDProvider>
                <Chessboard
                    id="chessboard"
                    data-testid="chessboard"
                    boardOrientation={`${((multiplayerState.playerColor === "w") || multiplayerState.isHost) ? "white" : "black"}`}
                    boardWidth={calcBoardWidth()}
                    autoPromoteToQueen={true}
                    onMouseOutSquare={() => setHoveredSquare(null)}
                    onMouseOverSquare={(square) => setHoveredSquare(square)}
                    position={gameState.fen}
                    onPieceDrop={onDrop}
                    isDraggablePiece={allowDrag}
                    onPieceDragBegin={onDragBegin}
                    onSquareClick={onSquareClick}
                    promotionDialogVariant="modal"
                    animationDuration={CustomBoardConstants.ANIMATION_DURATION}
                    customSquareStyles={highlightSquares()}
                    customDarkSquareStyle={{}} // Gets rid of default styles
                    customLightSquareStyle={{}}
                    customBoardStyle={{
                        transform: "perspective(1000px) rotateX(5deg)",
                        filter: "brightness(1.2)",
                        boxShadow: "-30px -10px 8px 0px rgba(0, 0, 0, 0.5)",
                        transformOrigin: "center",
                        borderRadius: "10px",
                        background: 'url("/images/chess-board.webp")',
                        padding: calcBoardPadding(),
                        backgroundPosition: "center",
                        backgroundSize: "100% 100%",
                        position: "relative",
                    }}
                    customPieces={useCustomPieces(
                        hoveredSquare,
                        clickedPiece,
                        onSquareClick,
                        remoteNavigation.remoteClickSquare,
                    )}
                />
            </ChessboardDnDProvider>
        </div>
    );
}
