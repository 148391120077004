export const CustomPiecesConstants = Object.freeze({
    ZOOM_FACTOR: 2.5,
    TILE_WIDTH_OFFSET: 0.7,
    PIECE_DICTIONARY: [
        { piece: "wP", x: -1398, y: 0 },
        { piece: "wN", x: -1235, y: 0 },
        { piece: "wB", x: -1561, y: 0 },
        { piece: "wR", x: -1071, y: 0 },
        { piece: "wQ", x: -1726, y: 0 },
        { piece: "wK", x: -946, y: 0 },
        { piece: "bP", x: -823, y: 0 },
        { piece: "bN", x: -3, y: 0 },
        { piece: "bB", x: -330, y: 0 },
        { piece: "bR", x: -495, y: 0 },
        { piece: "bQ", x: -166, y: 0 },
        { piece: "bK", x: -659, y: 0 },
    ],
});

export const CustomBoardConstants = Object.freeze({
    AI_RESPONSE_DELAY: 1000,
    ANIMATION_DURATION: 250,
    AI_PROMOTION_TYPE: "q",
    KING_SQUARE_INCHECK_STYLES: {
        background:
            "radial-gradient(circle, rgba(255, 0, 0, 0.8) 20%, rgba(255, 0, 0, 0.4) 50%, transparent 80%)",
        animation: "king-flash 1s infinite",
    },
    CAPTURE_MOVE_SQUARE_STYLES: "rgba(255, 0, 0, 0.5) 60%, transparent 70%",
    AVAIL_MOVE_SQUARE_STYLES: "rgba(0, 0, 0, 0.5) 20%, transparent 20%",
});

export const MultiplayerConstants = Object.freeze({
    WS_URL: "wss://3kex2sao88.execute-api.us-west-2.amazonaws.com/nonprod/",
    RETRY_DELAY: 3000,
    MAX_RECONNECT_RETRIES: 5,
    PING_INTERVAL: 30000,
});

export const AuthConstants = Object.freeze({
    AUTH_ENDPOINT:
        "https://lakeside-auth-api.nmp.nonprod-sinclairstoryline.com/auth/nonprod",
});

export const WaitingRoomConstants = Object.freeze({
    COPY_DELAY: 2000,
    QR_CODE_CONFIG: {
        width: 400,
        margin: 2,
        color: {
            dark: "#230C01",
            light: "#d9b996",
        },
    },
});
