import { useEffect } from "react";
import {
    handleClose,
    handleError,
    handleJoin,
    handleMessage,
} from "./handlers";
import { removeGameIDFromURL } from "../utils";
import { MultiplayerConstants } from "../constants";
import { useGame } from "../../components/game/GameContext";
// import { isAuthenticated, redirectToLoginPage } from '../auth';

/**
 * Custom hook to handle WebSocket connections for multiplayer games
 * @param {*} multiplayerState the multiplayer state
 * @param {*} gameState the game state
 * @param {*} setGameState the function to set the game state
 * @param {*} setMultiplayerState the function to set the multiplayer state
 */
export function useWebsocket(
    multiplayerState,
    gameState,
    setGameState,
    setMultiplayerState,
) {
    const { setLoading } = useGame();
    /**
     * Attempt to join a multiplayer game if joinGame is true and no WebSocket connection exists.
     * This handles joining a game, not hosting a game.
     */
    useEffect(() => {
        function joinGame() {
            // if (!(await isAuthenticated()))
            // {
            //     // Redirect the user to the login page if they are not authenticated.
            //     const redirectURL = window.location.href;
            //     redirectToLoginPage(redirectURL);
            //     return;
            // }

            if (
                multiplayerState.joinGame &&
                gameState.id &&
                !multiplayerState.ws
            ) {
                removeGameIDFromURL();
                setGameState((prevState) => ({
                    ...prevState,
                    isAiGame: false,
                    lastMove: null,
                }));
                const socket = new WebSocket(MultiplayerConstants.WS_URL);
                setMultiplayerState((prevState) => ({
                    ...prevState,
                    ws: socket,
                    isHost: false,
                }));

                socket.onopen = () => {
                    handleJoin(
                        gameState.id,
                        socket,
                        multiplayerState.playerOne,
                    );
                };

                socket.onmessage = (event) => {
                    handleMessage(
                        event,
                        setGameState,
                        setMultiplayerState,
                        setLoading,
                    );
                };

                socket.onclose = () => {
                    handleClose(setMultiplayerState, gameState, setGameState);
                };

                socket.onerror = (error) => {
                    handleError(error, socket);
                };
                setMultiplayerState((prevState) => ({
                    ...prevState,
                    ws: socket,
                }));
            }
        }
        if (
            multiplayerState.joinGame &&
            gameState.id &&
            multiplayerState.playerOne
        ) {
            joinGame();
        }
    }, [
        multiplayerState.joinGame,
        gameState.id,
        multiplayerState.ws,
        multiplayerState.playerOne,
    ]);
}
