/**
 * CustomPieces.js
 * This file contains the logic for creating custom chess pieces.
 *
 * @author Braden Zingler
 * Last modified 10/09/2024
 */
import { useCallback, useMemo } from "react";
import { CustomPiecesConstants } from "../../../utils/constants";
import "./index.css";

/**
 * Creates custom chess piece components with their own images and styles.
 * The images are pulled from the public/images directory.
 * Each piece has a name corresponding to the image file name.
 * (e.g. "wP.webp") would be the white pawn image.
 *
 * @param {String} hoveredSquare the square that the mouse is hovering over.
 * @param {String} clickedSquare the square that the mouse is clicked on.
 * @returns a dictionary of custom chess piece components.
 */
export const useCustomPieces = (
    hoveredSquare,
    clickedSquare,
    onSquareClick,
    remoteSquare,
    draggedPiece
) => {


    // Calculate the horizontal and vertical offset responsively.
    // This was added after the spritesheet was added to the project. Can probably do via CSS.
    const verticalOffset = (window.innerWidth > 1600 ? 100
        : (window.innerWidth > 1300 ? 95
        : (window.innerWidth > 1200 ? 95 
        : (window.innerWidth > 1100 ? 95 
        : (window.innerWidth > 900 ? 95 
        : (window.innerWidth > 400 ? 85 
        : (window.innerWidth > 350 ? 78
        : 95)
    ))))));

    const horizontalOffset = (window.innerWidth > 1600 ? 130
        : (window.innerWidth > 1400 ? 110
        : (window.innerWidth > 1300 ? 100
        : (window.innerWidth > 1200 ? 95 
        : (window.innerWidth > 1100 ? 110
        : (window.innerWidth > 900 ? 100
        : (window.innerWidth > 800 ? 110
        : (window.innerWidth > 600 ? 97
        : (window.innerWidth > 500 ? 83
        : (window.innerWidth > 400 ? 77
        : (window.innerWidth > 300 ? 65
        : 83)
    ))))))))));

    /**
     * Calculates the bottom offset for the chess piece.
     * Centers the piece vertically within the tile.
     * @param {Number} squareWidth the width of the square.
     * @returns the CSS setting for the bottom offset of the chess piece.
     */
    const calculateBottomOffset = (squareWidth, isDragging, isRemoteSelected) => {
        const tilePos = CustomPiecesConstants.TILE_WIDTH_OFFSET * squareWidth 
                        * ((isDragging || isRemoteSelected) ? 2 : 1)
                        + verticalOffset;
        return `${tilePos - (CustomPiecesConstants.ZOOM_FACTOR - 1) * squareWidth}px`;
    };


    /**
     * Calculates the left offset for the chess piece.
     * Centers the piece horizontally.
     * @param {Number} squareWidth the width of the square.
     * @returns the CSS setting for the left offset of the chess piece.
     */
    const calculateLeftOffset = (squareWidth) => {
        return `${((1 - CustomPiecesConstants.ZOOM_FACTOR) * squareWidth + horizontalOffset) / 2}px`;
    };

    /**
     * Creates the reflection effect for the chess piece.
     * @param {Number} squareWidth the width of the square.
     * @returns the CSS setting for the reflection effect.
     */
    // const getReflection = useCallback((squareWidth) => {
    //     const tilePos = CustomPiecesConstants.TILE_WIDTH_OFFSET * squareWidth;
    //     const reflLocation =
    //         tilePos - (CustomPiecesConstants.ZOOM_FACTOR - 1) * squareWidth * 2;

    //     if (window.innerWidth < 900) {
    //         // Disable reflections on mobile devices to improve performance.
    //         return ``;
    //     }
    //     return `below ${reflLocation}px linear-gradient(transparent, rgba(0, 0, 0, 0.3))`;
    // }, []);

    return useMemo(() => {
        const pieceComponents = {};
        CustomPiecesConstants.PIECE_DICTIONARY.forEach(({ piece, x, y }) => {
            pieceComponents[piece] = ({ squareWidth, square }) => {
                let isHovered = hoveredSquare === square;
                const isRemoteSelected = remoteSquare === square;
                const isDragging = draggedPiece === square;

                // Disable hover effects on mobile devices
                if (window.innerWidth < 600) {
                    isHovered = false;
                }

                return (
                    <div
                        className="piece"
                        id={piece}
                        data-testid={square}
                        style={{
                            backgroundPosition: `${x}px ${y}px`,
                            bottom: calculateBottomOffset(squareWidth, isDragging, isRemoteSelected),
                            left: calculateLeftOffset(squareWidth),
                            opacity: isHovered || isRemoteSelected ? 0.6 : 1,
                            transform: `${isDragging || isRemoteSelected ? `scale(1.5)` : ""}`,
                        }}
                        onTouchStart={() => onSquareClick(square)}
                        onClick={() => onSquareClick(square)}
                        alt={square}
                    >
                    </div>
                );
            };
        });
        return pieceComponents;
        // eslint-disable-next-line
    }, [hoveredSquare, clickedSquare]);
};
