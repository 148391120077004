import { useEffect } from "react";
import { useGame } from "../../game/GameContext";
import "./index.css";

/**
 * Creates the loading screen component.
 */
export default function LoadingScreen() {
    const { setMultiplayerState, loading, setLoading } = useGame();

    // Immediately focus the first button when the loading screen is rendered.
    useEffect(() => {
        const startButton = document.querySelectorAll("button")[0];
        startButton?.focus();
    });

    if (!loading.isLoading) {
        return null;
    }

    /**
     * Closes the loading screen and sets the joinGame state to false.
     */
    function onClick() {
        setLoading({ isLoading: false, message: null });
        setMultiplayerState((prevState) => ({ ...prevState, joinGame: false }));
    }

    return (
        <div className="loading-screen">
            <div id="elements">
                <h2>{loading.message || "Waiting for game to start..."}</h2>
                {<div className="dot-spin"></div>}
                <button onClick={onClick} className="chess-button">
                    Home
                </button>
            </div>
        </div>
    );
}
