/**
 * StartMenu/index.js
 * The start menu for the chess app.
 *
 * @component StartMenu
 * @author Braden Zingler
 * Last modified 10/07/2024
 */
import React, { useEffect } from "react";
import { useGame } from "../../game/GameContext";
import { quitGame } from "../../../utils/GameCenter";
import { Chess } from "chess.js";
import "./index.css";
import {
    isAuthenticated,
    logout,
    redirectToLoginPage,
} from "../../../utils/auth";
import { activeButton } from "../../../utils/keys/KeyHandlers";

export default function StartMenu() {
    const {
        gameState,
        setGameState,
        setMultiplayerState,
        showMultiplayerMenu,
        setShowMultiplayerMenu,
        setRemoteNavigation,
        setLoading,
    } = useGame();

    /**
     * Start a game against the computer.
     * @param {Event} e the click event
     */
    function handlePlayComputerClick(e) {
        e.preventDefault();
        setMultiplayerState((prev) => ({ ...prev, playerColor: "w" }));
        const savedGame = localStorage.getItem("ai-chessGameState");
        const chessInstance = savedGame ? new Chess(savedGame) : new Chess();
        setGameState((prev) => ({
            ...prev,
            fen: chessInstance.fen(),
            id: null,
            game: chessInstance,
            isAIGame: true,
            showGame: true,
        }));
        setRemoteNavigation((prev) => ({
            ...prev,
            remoteOverSquare: "a1",
            remoteClickSquare: null,
        }));
    }

    /**
     * Handles the multiplayer button click event.
     * Shows the multiplayer menu
     * @param {Event} e the click event from the button.
     */
    async function handleMultiplayerClick(e) {
        e.preventDefault();
        if (process.env.NODE_ENV === "development") {
            setShowMultiplayerMenu(true);
            return;
        }
        setLoading({ isLoading: true, message: "Loading..." });
        const userData = await isAuthenticated();
        if (userData) {
            setGameState((prev) => ({ ...prev, userData: userData }));
            setShowMultiplayerMenu(true);
        } else {
            redirectToLoginPage(window.location.href);
        }
        setLoading({ isLoading: false, message: null });
    }

    // Immediately focus the first button when the start menu is rendered.
    useEffect(() => {
        const startButton = document.querySelectorAll("button")[activeButton];
        startButton?.focus();
    });

    // Do not show the start menu if the game is already started.
    if (gameState.showGame || showMultiplayerMenu) {
        return null;
    }

    return (
        <div className="start-menu" data-testid="start-menu">
            <h2>Lakeside Chess</h2>
            <button
                className="chess-button"
                onClick={(e) => handlePlayComputerClick(e)}
                data-testid="start-button"
            >
                Play Computer
            </button>
            <button
                onClick={async (e) => await handleMultiplayerClick(e)}
                className="chess-button"
            >
                Multiplayer
            </button>
            {gameState.userData && (
                <button onClick={logout} className="chess-button">
                    Logout
                </button>
            )}
            <button onClick={quitGame} className="chess-button">
                Quit
            </button>
        </div>
    );
}
