import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { GameProvider } from "./components/game/GameContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <GameProvider>
            <App id="fullscreen_iframe" data-testid="app" />
        </GameProvider>
    </React.StrictMode>,
);
