import React from "react";
import "./index.css";
import { useGame } from "../../game/GameContext";

/**
 * Indicates whose turn it is in the game.
 * @returns the current turn indicator
 */
export default function TurnIndicator() {
    const { gameState, multiplayerState } = useGame();

    if (gameState.isAIGame || multiplayerState.isHost || gameState.game.isGameOver()) {
        return null;
    }

    const className =
        gameState.game.turn() === multiplayerState.playerColor
            ? "flashing-turn"
            : "";
    const text =
        gameState.game.turn() === multiplayerState.playerColor
            ? `It's your turn!`
            : `Opponents move...`;

    return (
        <div id="current-turn" className={className}>
            {text}
        </div>
    );
}
