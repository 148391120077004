/**
 * Cards.js
 * The Cards component creates the cards on the table next to the board.
 *
 * @author Braden Zingler
 * Last modified 09/17/24
 */
import "./index.css";

export default function Cards() {
    const showCards = window.innerWidth > 1200;

    if (showCards) {
        return (
            <div className={`cards`} data-testid="cards">
                <img
                    src="images/cards.webp"
                    alt="Cards"
                    className="cards-image"
                />
            </div>
        );
    }
}
