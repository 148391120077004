import { AuthConstants } from "./constants";

/**
 * This function validates the users JWT, if they have one.
 * Returns null if the user is not authenticated, else returns the user data.
 */
export async function isAuthenticated() {
    try {
        const response = await fetch(AuthConstants.AUTH_ENDPOINT, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            credentials: "include",
        });
        if (response.ok) {
            const data = await response.json();
            return data?.ok?.access === "user" ? data : null;
        }
        return null;
    } catch (error) {
        console.log("Error during authentication check:", error);
        return null;
    }
}

/**
 * Redirects the user to the login page of the cabin. After they have logged in, they will be
 * redirected back to the redirectURL that you provide.
 * At this point you should again check if the user is authenticated before allowing them to proceed.
 * localhost:3001/login#redirect_google.com
 * The below URL will hopefully not be hardcoded someday, or on the same domain atleast. Could probably do something with iFrames.
 * @param {String} redirectURL the URL to redirect the user back to after they have logged in.
 */
export function redirectToLoginPage(redirectURL) {
    if (process.env.NODE_ENV === "development") {
        window.location.href = `http://localhost:3001/login#game_redirect_${redirectURL}`;
    } else {
        window.location.href = `https://nonprod-lakeside-cabin.nmp.nonprod-sinclairstoryline.com/login#game_redirect_${redirectURL}`;
    }
}

/**
 * Logs the user out of the cabin. This will invalidate their JWT.
 */
export function logout() {
    fetch(AuthConstants.AUTH_ENDPOINT, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        credentials: "include",
        body: JSON.stringify({ action: "logout" }),
    }).catch((error) => {
        console.log("Error during logout:", error);
    });
}
