/**
 * MoveAudio/index.js
 * Handles the move audio playing when a piece is moved.
 * The move audio is within public/audio/piece-jump.mp3.
 *
 * @author Braden Zingler
 * Last modified 09/17/24
 */
import { useCallback } from "react";

// Initialize the move sound.
const moveSound = new Audio("/audio/piece-move.mp3");
moveSound.preload = "auto"; // preload the audio

const usePlayAudio = () => {
    return useCallback(() => {
        // Add some variation to each play
        moveSound.playbackRate = 1.3;
        moveSound.volume = 0.3 + Math.random() * 0.2;
        moveSound.play().catch((error) => {
            console.log("Error playing move sound: ", error);
        });
    }, []);
};

export default usePlayAudio;
