/**
 * Candle.js
 * The candle component in the top left of the screen.
 * The candle component consists of the flame and the actual candle.
 * The flame has some CSS animations (in ./Candle.css) to make it appear as though it is moving.
 *
 * @author Braden Zingler
 * Last modified 09/17/24
 */
import "./index.css";

export default function Candle() {
    const showCandle = window.innerWidth > 1200;

    // Only show the candle when the screen is wide enough.
    if (showCandle) {
        return (
            <div className={`candle`} data-testid="candle">
                <img
                    src="images/candle-flame.webp"
                    alt="Flame"
                    className="flame-image"
                />
                <img
                    src="images/candle.webp"
                    alt="Candle"
                    className="candle-image"
                />
            </div>
        );
    }
}
